import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import styled from 'styled-components';

const Breadcrumbs = styled(MuiBreadcrumbs)`
  & a,
  & span {
    text-decoration: none;
    ${props => props.theme.typo.bodyS};
    color: ${props => props.theme.color.c300};
  }

  & span {
    ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.color.g400};
  }

  & a:hover {
    color: ${props => props.theme.color.c400};
  }

  & p {
    ${props => props.theme.typo.bodyS};
    ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.color.g400};
  }
`;

export { Breadcrumbs };
