import { ContactUsPanel } from '@features/contact-us-page/contact-us-panel';
import { ContactUsSection } from '@features/contact-us-page/contact-us-section';
import { PardotForm } from '@features/contact-us-page/pardot-form';
import { PhonesSection } from '@features/contact-us-page/phones-section';
import { LayoutDefault } from '@features/layouts/layout-default';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import React from 'react';

const ContactUsPage = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutDefault locale={locale} location={location}>
      <SEO
        location={location}
        title="contactUs.seo.title"
        description="contactUs.seo.description"
        lang={locale}
      />
      <ContactUsSection />
      <ContactUsPanel />
      <PhonesSection />
      <PardotForm
        pardotFormTitle="sayHello"
        subtitle="getInTouch"
        pardotURL="https://go.icontainers.com/l/427912/2018-12-21/5m6xvp?Final_Source=Web%20Contact%20us"
      />
    </LayoutDefault>
  );
};

ContactUsPage.propTypes = {
  ...gatsbyPageProps,
};

export { ContactUsPage };
export default ContactUsPage;
