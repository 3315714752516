import { Breadcrumbs as BreadcrumbsBase } from '@organisms/breadcrumbs';
import styled from 'styled-components';

import { Column as ColumnComponent, Row as RowComponent } from '@atoms/Grid';
import { PageContainer } from '@molecules/Containers';

const Row = styled(RowComponent)`
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  @media ${props => props.theme.query.tablet} {
    justify-content: center;
    margin-bottom: 40px;
  }
  @media ${props => props.theme.query.desktop} {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const Breadcrumbs = styled(BreadcrumbsBase)`
  color: ${props => props.theme.color.g400};
`;

const Image = styled.img`
  vertical-align: bottom;

  @media ${props => props.theme.query.mobile} {
    align: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
`;

const LeftColumn = styled(ColumnComponent)`
  padding: 30px;
  h1 {
    ${props => props.theme.typo.displayXL};
    ${props => props.theme.fontWeight.medium};
  }

  @media ${props => props.theme.query.tablet} {
    justify-content: center;
  }

  @media ${props => props.theme.query.mobile} {
    h1 {
      ${props => props.theme.typo.displayL};
    }
  }
`;

const TextDiv = styled.div`
  @media ${props => props.theme.query.tablet} {
    padding: 30px;
    justify-content: center;
  }

  p {
    ${props => props.theme.typo.displayXL};
    ${props => props.theme.fontWeight.medium};
    @media ${props => props.theme.query.tablet} {
      justify-content: center;
      text-align: center;
    }
    @media ${props => props.theme.query.mobile} {
      ${props => props.theme.typo.displayM};
      ${props => props.theme.fontWeight.demi};
      justify-content: center;
      text-align: center;
    }
  }

  span {
    ${props => props.theme.fontWeight.book};
    display: flex;
    flex-direction: column-reverse;
    flex-flow: row wrap;
    flex-wrap: wrap-reverse;
    justify-content: center;
    text-align: center;
    margin-top: 2px;

    @media ${props => props.theme.query.desktop} {
      ${props => props.theme.typo.displayS};
    }
  }
`;

const Description = styled.p`
  ${props => props.theme.typo.bodyL};
  ${props => props.theme.fontWeight.book};
  color: ${props => props.theme.color.black};
  margin-top:10px;
  margin bottom:20px;

  display: flex;
  flex-direction: column-reverse;
  flex-flow: row wrap;
  flex-wrap: wrap-reverse;

  @media ${props => props.theme.query.tablet} {
    height: 60px;
    justify-content: center;
    text-align: center;
  }
  @media ${props => props.theme.query.mobile} {
    justify-content: center;
  }

  @media ${props => props.theme.query.desktop} {
    width: 80%;
  }
`;

const LinkDescription = styled.div`
  ${props => props.theme.typo.bodyS};
  ${props => props.theme.fontWeight.book};
  color: ${props => props.theme.color.black};
  display: flex;
  flex-direction: column-reverse;
  flex-flow: row wrap;
  flex-wrap: wrap-reverse;

  @media ${props => props.theme.query.tablet} {
    justify-content: center;
    text-align: center;
  }
  @media ${props => props.theme.query.mobile} {
    justify-content: center;
    text-align: center;
  }
`;
const Wrapper = styled(PageContainer)`
  padding: 50px 0;
`;
const RightColumn = styled(ColumnComponent)`
  display: flex;
  justify-content: left;
  overflow: hidden;

  @media ${props => props.theme.query.tablet} {
    justify-content: center;
  }
  @media ${props => props.theme.query.mobile} {
    justify-content: center;
  }
`;

export {
  Row,
  LeftColumn,
  Wrapper,
  LinkDescription,
  TextDiv,
  Breadcrumbs,
  RightColumn,
  Image,
  Description,
};
