import createIcon from '@molecules/icons/createIcon';
import React from 'react';

const IndiaFlagIcon = createIcon({
  viewBox: '0 0 70 70',
  path: (
    <>
      <path
        d="M68.7929 57.9311H1.20695C0.540448 57.9311 0 57.3907 0 56.7242V42.6438H69.9999V56.7242C69.9999 57.3908 69.4594 57.9311 68.7929 57.9311Z"
        fill="#73AF00"
      />
      <path
        d="M69.9999 27.3565H0V13.276C0 12.6095 0.540448 12.0691 1.20695 12.0691H68.7931C69.4596 12.0691 70 12.6095 70 13.276L69.9999 27.3565Z"
        fill="#FAB446"
      />
      <path d="M69.9999 27.3562H0V42.6427H69.9999V27.3562Z" fill="#F5F5F5" />
      <path
        d="M34.9997 41.4872C31.4226 41.4872 28.5127 38.5773 28.5127 35.0002C28.5127 31.4231 31.4226 28.5132 34.9997 28.5132C38.5768 28.5132 41.4867 31.4231 41.4867 35.0002C41.4867 38.5773 38.5768 41.4872 34.9997 41.4872ZM34.9997 29.72C32.0879 29.72 29.7195 32.0884 29.7195 35.0002C29.7195 37.912 32.0879 40.2804 34.9997 40.2804C37.9115 40.2804 40.2799 37.912 40.2799 35.0002C40.2799 32.0884 37.9115 29.72 34.9997 29.72Z"
        fill="#41479B"
      />
      <path
        d="M34.9991 35.7355C35.4052 35.7355 35.7345 35.4062 35.7345 35.0001C35.7345 34.5939 35.4052 34.2646 34.9991 34.2646C34.5929 34.2646 34.2637 34.5939 34.2637 35.0001C34.2637 35.4062 34.5929 35.7355 34.9991 35.7355Z"
        fill="#41479B"
      />
      <path
        d="M34.9998 35.1105L33.1308 35.2992L29.1162 35.1105V34.8898L33.1308 34.7012L34.9998 34.8898V35.1105Z"
        fill="#41479B"
      />
      <path
        d="M34.999 35.1105L36.868 35.2992L40.8826 35.1105V34.8898L36.868 34.7012L34.999 34.8898V35.1105Z"
        fill="#41479B"
      />
      <path
        d="M34.8898 35.0003L34.7012 33.1313L34.8898 29.1167H35.1105L35.2992 33.1313L35.1105 35.0003H34.8898Z"
        fill="#41479B"
      />
      <path
        d="M34.8898 35.0002L34.7012 36.8692L34.8898 40.8838H35.1105L35.2992 36.8692L35.1105 35.0002H34.8898Z"
        fill="#41479B"
      />
      <path
        d="M34.9211 35.0782L33.4661 33.8902L30.7607 30.918L30.9167 30.762L33.889 33.4673L35.0769 34.9223L34.9211 35.0782Z"
        fill="#41479B"
      />
      <path
        d="M34.9219 35.0782L36.1098 36.5332L39.0821 39.2386L39.2381 39.0826L36.5327 36.1103L35.0777 34.9224L34.9219 35.0782Z"
        fill="#41479B"
      />
      <path
        d="M34.9219 34.9223L36.1098 33.4673L39.0821 30.762L39.2381 30.918L36.5327 33.8902L35.0777 35.0782L34.9219 34.9223Z"
        fill="#41479B"
      />
      <path
        d="M34.9211 34.9224L33.4661 36.1103L30.7607 39.0826L30.9167 39.2386L33.889 36.5332L35.0769 35.0782L34.9211 34.9224Z"
        fill="#41479B"
      />
      <path
        d="M35.0416 35.1022L33.3871 35.9917L29.606 37.3537L29.5215 37.1498L33.1583 35.4392L34.9571 34.8982L35.0416 35.1022Z"
        fill="#41479B"
      />
      <path
        d="M35.0425 35.1022L36.8413 34.5612L40.4781 32.8506L40.3936 32.6467L36.6126 34.0087L34.958 34.8982L35.0425 35.1022Z"
        fill="#41479B"
      />
      <path
        d="M34.897 35.0424L34.0075 33.3878L32.6455 29.6067L32.8494 29.5222L34.56 33.1591L35.101 34.9579L34.897 35.0424Z"
        fill="#41479B"
      />
      <path
        d="M34.8975 35.0425L35.4385 36.8413L37.1491 40.4781L37.3529 40.3936L35.9909 36.6126L35.1014 34.958L34.8975 35.0425Z"
        fill="#41479B"
      />
      <path
        d="M34.9571 35.1022L33.1583 34.5612L29.5215 32.8506L29.606 32.6467L33.3871 34.0087L35.0416 34.8982L34.9571 35.1022Z"
        fill="#41479B"
      />
      <path
        d="M34.958 35.1022L36.6126 35.9917L40.3936 37.3537L40.4781 37.1498L36.8413 35.4392L35.0425 34.8982L34.958 35.1022Z"
        fill="#41479B"
      />
      <path
        d="M34.8975 34.9579L35.4385 33.1591L37.1491 29.5222L37.3529 29.6067L35.9909 33.3878L35.1014 35.0424L34.8975 34.9579Z"
        fill="#41479B"
      />
      <path
        d="M34.897 34.958L34.0075 36.6126L32.6455 40.3936L32.8494 40.4781L34.56 36.8413L35.101 35.0425L34.897 34.958Z"
        fill="#41479B"
      />
      <path
        d="M34.9998 35.9919C35.5477 35.9919 35.9919 35.5477 35.9919 34.9998C35.9919 34.452 35.5477 34.0078 34.9998 34.0078C34.452 34.0078 34.0078 34.452 34.0078 34.9998C34.0078 35.5477 34.452 35.9919 34.9998 35.9919Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.9992 35.595C35.3277 35.595 35.594 35.3287 35.594 35.0001C35.594 34.6716 35.3277 34.4053 34.9992 34.4053C34.6706 34.4053 34.4043 34.6716 34.4043 35.0001C34.4043 35.3287 34.6706 35.595 34.9992 35.595Z"
        fill="#41479B"
      />
    </>
  ),
});

export { IndiaFlagIcon };
