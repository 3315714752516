import createIcon from '@molecules/icons/createIcon';
import React from 'react';

const SpainFlagIcon = createIcon({
  viewBox: '0 0 70 70',
  path: (
    <>
      <path
        d="M1.20695 57.9309H68.7931C69.4596 57.9309 70 57.3905 70 56.724V13.2758C70 12.6093 69.4596 12.0688 68.7931 12.0688H1.20695C0.540448 12.069 0 12.6093 0 13.2758V56.724C0 57.3906 0.540448 57.9309 1.20695 57.9309Z"
        fill="#C8414B"
      />
      <path d="M69.9999 21.7246H0V48.2767H69.9999V21.7246Z" fill="#FFD250" />
      <path
        d="M29.569 35L30.5924 30.3952C30.6867 29.9711 30.3639 29.5688 29.9294 29.5688H29.2086C28.7743 29.5688 28.4516 29.9711 28.5457 30.3952L29.569 35Z"
        fill="#C8414B"
      />
      <path
        d="M30.7761 32.5857H28.3623V42.8437H30.7761V32.5857Z"
        fill="#F5F5F5"
      />
      <path
        d="M31.3785 31.3799H27.7578V32.5868H31.3785V31.3799Z"
        fill="#FAB446"
      />
      <path d="M31.3795 35H25.3447V36.207H31.3795V35Z" fill="#C8414B" />
      <path
        d="M31.3795 39.8276L27.7588 38.6208V37.4138L31.3795 38.6208V39.8276Z"
        fill="#C8414B"
      />
      <path
        d="M11.4655 35L12.4888 30.3952C12.5832 29.9711 12.2604 29.5688 11.8259 29.5688H11.1051C10.6708 29.5688 10.3481 29.9711 10.4422 30.3952L11.4655 35Z"
        fill="#C8414B"
      />
      <path
        d="M15.6894 31.3794C15.0229 31.3794 14.4824 31.9198 14.4824 32.5863V40.4311C14.4824 41.8974 15.851 44.6553 20.5169 44.6553C25.1828 44.6553 26.5514 41.8976 26.5514 40.4311V32.5863C26.5514 31.9198 26.0109 31.3794 25.3444 31.3794H15.6894V31.3794Z"
        fill="#F5F5F5"
      />
      <path
        d="M20.5169 37.4139H14.4824V32.5863C14.4824 31.9198 15.0229 31.3794 15.6894 31.3794H20.5169V37.4139Z"
        fill="#C8414B"
      />
      <path
        d="M20.5166 37.4138H26.5511V40.4311C26.5511 42.0975 25.2003 43.4483 23.5338 43.4483C21.8674 43.4483 20.5166 42.0975 20.5166 40.4311V37.4138V37.4138Z"
        fill="#C8414B"
      />
      <path
        d="M14.4824 37.4138H20.5169V40.4311C20.5169 42.0975 19.1661 43.4483 17.4997 43.4483C15.8332 43.4483 14.4824 42.0975 14.4824 40.4311V37.4138V37.4138Z"
        fill="#FAB446"
      />
      <path
        d="M19.3105 42.8313V37.4138H18.1035V43.3875C18.5525 43.2964 18.9581 43.0976 19.3105 42.8313Z"
        fill="#C8414B"
      />
      <path
        d="M16.8964 43.3875V37.4138H15.6895V42.8313C16.0419 43.0976 16.4476 43.2964 16.8964 43.3875Z"
        fill="#C8414B"
      />
      <path d="M19.3102 35H15.6895V36.207H19.3102V35Z" fill="#FFB441" />
      <path
        d="M19.3102 32.5857H15.6895V33.7926H19.3102V32.5857Z"
        fill="#FAB446"
      />
      <path
        d="M18.7058 33.3035H16.292V35.4899H18.7058V33.3035Z"
        fill="#FAB446"
      />
      <path
        d="M12.6716 32.5857H10.2578V42.8437H12.6716V32.5857Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.276 42.2422H9.65527V43.4491H13.276V42.2422Z"
        fill="#FAB446"
      />
      <path
        d="M13.276 31.3799H9.65527V32.5868H13.276V31.3799Z"
        fill="#FAB446"
      />
      <path
        d="M13.8793 43.448H9.05176V44.6549H13.8793V43.448Z"
        fill="#5064AA"
      />
      <path
        d="M31.983 42.2419H28.3623V43.4489H31.983V42.2419Z"
        fill="#FAB446"
      />
      <path
        d="M31.9828 43.448H27.1553V44.6549H31.9828V43.448Z"
        fill="#5064AA"
      />
      <path
        d="M24.1389 30.1726H16.8975V31.3796H24.1389V30.1726Z"
        fill="#FAB446"
      />
      <path d="M21.121 26.5522H19.9141V30.173H21.121V26.5522Z" fill="#FFB441" />
      <path
        d="M19.3103 28.362C18.312 28.362 17.5 27.5499 17.5 26.5517C17.5 25.5534 18.3121 24.7415 19.3103 24.7415C20.3086 24.7415 21.1206 25.5536 21.1206 26.5517C21.1207 27.5499 20.3086 28.362 19.3103 28.362ZM19.3103 25.9483C18.9774 25.9483 18.7068 26.2187 18.7068 26.5517C18.7068 26.8847 18.9772 27.1552 19.3103 27.1552C19.6433 27.1552 19.9138 26.8848 19.9138 26.5517C19.9138 26.2188 19.6433 25.9483 19.3103 25.9483Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.7244 28.362C20.726 28.362 19.9141 27.5499 19.9141 26.5517C19.9141 25.5534 20.7262 24.7415 21.7244 24.7415C22.7227 24.7415 23.5346 25.5536 23.5346 26.5517C23.5346 27.5499 22.7225 28.362 21.7244 28.362ZM21.7244 25.9483C21.3914 25.9483 21.1209 26.2187 21.1209 26.5517C21.1209 26.8847 21.3913 27.1552 21.7244 27.1552C22.0573 27.1552 22.3278 26.8848 22.3278 26.5517C22.3277 26.2188 22.0573 25.9483 21.7244 25.9483Z"
        fill="#F5F5F5"
      />
      <path
        d="M24.1374 29.5691C23.1391 29.5691 22.3271 28.757 22.3271 27.7588C22.3271 26.7605 23.1393 25.9485 24.1374 25.9485C25.1358 25.9485 25.9477 26.7606 25.9477 27.7588C25.9477 28.757 25.1356 29.5691 24.1374 29.5691ZM24.1374 27.1553C23.8045 27.1553 23.534 27.4257 23.534 27.7588C23.534 28.0917 23.8044 28.3623 24.1374 28.3623C24.4703 28.3623 24.7409 28.0918 24.7409 27.7588C24.7409 27.4257 24.4703 27.1553 24.1374 27.1553Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.8962 29.5691C15.8979 29.5691 15.0859 28.757 15.0859 27.7588C15.0859 26.7605 15.898 25.9485 16.8962 25.9485C17.8945 25.9485 18.7065 26.7606 18.7065 27.7588C18.7067 28.757 17.8945 29.5691 16.8962 29.5691ZM16.8962 27.1553C16.5633 27.1553 16.2928 27.4257 16.2928 27.7588C16.2928 28.0917 16.5632 28.3623 16.8962 28.3623C17.2293 28.3623 17.4997 28.0917 17.4997 27.7586C17.4997 27.4257 17.2293 27.1553 16.8962 27.1553Z"
        fill="#F5F5F5"
      />
      <path
        d="M24.1375 39.8277V40.4311C24.1375 40.7639 23.8668 41.0346 23.5341 41.0346C23.2013 41.0346 22.9306 40.7639 22.9306 40.4311V39.8277H24.1375ZM25.3443 38.6208H21.7236V40.4311C21.7236 41.4295 22.5357 42.2414 23.5339 42.2414C24.5322 42.2414 25.3442 41.4293 25.3442 40.4311V38.6208H25.3443Z"
        fill="#FAB446"
      />
      <path
        d="M23.5341 36.207C22.8676 36.207 22.3271 35.6666 22.3271 35.0001V33.7931C22.3271 33.1266 22.8676 32.5862 23.5341 32.5862C24.2006 32.5862 24.7411 33.1266 24.7411 33.7931V35.0001C24.7411 35.6666 24.2006 36.207 23.5341 36.207Z"
        fill="#FFA0D2"
      />
      <path
        d="M20.5173 39.2234C21.5171 39.2234 22.3276 38.4129 22.3276 37.4131C22.3276 36.4133 21.5171 35.6028 20.5173 35.6028C19.5175 35.6028 18.707 36.4133 18.707 37.4131C18.707 38.4129 19.5175 39.2234 20.5173 39.2234Z"
        fill="#5064AA"
      />
      <path
        d="M21.121 24.1377H19.9141V27.7584H21.121V24.1377Z"
        fill="#FAB446"
      />
      <path
        d="M16.8964 30.1725L15.6895 28.9655L16.3964 28.2586C17.4892 27.1658 18.9715 26.5518 20.5171 26.5518C22.0627 26.5518 23.5449 27.1658 24.6378 28.2586L25.3448 28.9655L24.1378 30.1725H16.8964Z"
        fill="#C8414B"
      />
      <path
        d="M20.5166 29.5688C20.8499 29.5688 21.12 29.2986 21.12 28.9653C21.12 28.632 20.8499 28.3618 20.5166 28.3618C20.1833 28.3618 19.9131 28.632 19.9131 28.9653C19.9131 29.2986 20.1833 29.5688 20.5166 29.5688Z"
        fill="#FFD250"
      />
      <path
        d="M18.1025 29.5688C18.4358 29.5688 18.706 29.2986 18.706 28.9653C18.706 28.632 18.4358 28.3618 18.1025 28.3618C17.7692 28.3618 17.499 28.632 17.499 28.9653C17.499 29.2986 17.7692 29.5688 18.1025 29.5688Z"
        fill="#FFD250"
      />
      <path
        d="M22.9296 29.5688C23.2629 29.5688 23.5331 29.2986 23.5331 28.9653C23.5331 28.632 23.2629 28.3618 22.9296 28.3618C22.5964 28.3618 22.3262 28.632 22.3262 28.9653C22.3262 29.2986 22.5964 29.5688 22.9296 29.5688Z"
        fill="#FFD250"
      />
      <path d="M15.69 35H9.65527V36.207H15.69V35Z" fill="#C8414B" />
      <path
        d="M9.65527 39.8276L13.2759 38.6208V37.4138L9.65527 38.6208V39.8276Z"
        fill="#C8414B"
      />
    </>
  ),
});

export { SpainFlagIcon };
