import { useTranslation } from '@infra/i18n';
import React from 'react';

import CustomerService from './Images/CustomerService.svg';
import EffortlessShipping from './Images/EffortlessShipping.svg';
import RealTimeTracking from './Images/RealTimeTracking.svg';
import {
  Column,
  Container,
  Image,
  InnerWrapper,
  Row,
  Text,
} from './styled-components';

const ContactUsPanel = () => {
  const { t } = useTranslation('contactUs');

  return (
    <Container>
      <InnerWrapper>
        <Row data-testid="row">
          <Column sm={12} md={4} lg={3}>
            <Image
              src={EffortlessShipping}
              alt={t('EffortlessShipping')}
              width="50"
              height="30"
            />
            <Text>{t('EffortlessShipping')}</Text>
          </Column>
          <Column sm={12} md={4} lg={3}>
            <Image
              src={RealTimeTracking}
              alt={t('RealTimeTracking')}
              width="50"
              height="30"
            />
            <Text>{t('Real-timeTracking')}</Text>
          </Column>
          <Column sm={12} md={4} lg={3}>
            <Image
              src={CustomerService}
              alt={t('CustomerService')}
              width="50"
              height="30"
            />
            <Text>{t('CustomerService')}</Text>
          </Column>
        </Row>
      </InnerWrapper>
    </Container>
  );
};

export { ContactUsPanel };
