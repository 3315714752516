import PropTypes from 'prop-types';
import React from 'react';

import { Breadcrumbs as BreadcrumbsBase } from './styled-components';
import { createBreadcrumb } from './utils';

const Breadcrumbs = props => {
  const { items, ...rest } = props;

  return (
    <BreadcrumbsBase {...rest}>{items.map(createBreadcrumb)}</BreadcrumbsBase>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
};

export { Breadcrumbs };
