import { useTranslation } from '@infra/i18n';
import { getPageBreadcrumbs } from '@organisms/breadcrumbs';
import PropTypes from 'prop-types';
import React from 'react';

import ContactUsV2 from './Images/ContactUsV2.svg';
import {
  Breadcrumbs,
  Description,
  Image,
  LeftColumn,
  LinkDescription,
  RightColumn,
  Row,
  TextDiv,
  Wrapper,
} from './styled-components';

const ContactUsSection = props => {
  const { title, categoryUrl, categoryTitle } = props;
  const { t } = useTranslation('');

  const breadcrumbs = getPageBreadcrumbs({
    categoryUrl,
    categoryTitle,
    headerTitle: title,
    t,
  });

  return (
    <Wrapper>
      <Row>
        <LeftColumn sm={12} md={12} lg={8}>
          <TextDiv data-testid="link">
            <LinkDescription>
              <Breadcrumbs items={breadcrumbs} />
              <span>{t('contactUs.ContactUs')}</span>
            </LinkDescription>
            <h1>{t('contactUs.Weareavailable')}</h1>
          </TextDiv>

          <Description data-testid="HeaderText">
            {t('contactUs.OurcustomerSupportTeam')}
          </Description>
        </LeftColumn>

        <RightColumn sm={12} md={12} lg={4}>
          <Image src={ContactUsV2} alt={t('ContactUsV2')} />
        </RightColumn>
      </Row>
    </Wrapper>
  );
};

ContactUsSection.propTypes = {
  title: PropTypes.string.isRequired,
  categoryUrl: PropTypes.string,
  categoryTitle: PropTypes.string,
};

ContactUsSection.defaultProps = {
  categoryUrl: null,
  categoryTitle: null,
};

export { ContactUsSection };
