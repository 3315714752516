import styled from 'styled-components';

import { Column as ColumnBase, Row } from '@atoms/Grid';

const Wrapper = styled.div`
  overflow: hidden;
  padding: 0px 15px;
  background-color: ${props => props.theme.color.white};
  @media ${props => props.theme.query.desktop} {
    margin: 150px 0px 85px 0px;
  }
  @media ${props => props.theme.query.tablet} {
    margin: 100px 0px 50px 0px;
  }
  @media ${props => props.theme.query.mobile} {
    margin: 100px 0px 70px 0px;
  }
`;

const FlagsWrapper = styled.div`
  margin: 50px auto 0;

  @media ${props => props.theme.query.tablet} {
    max-width: 480px;
  }

  @media ${props => props.theme.query.desktop} {
    max-width: 786px;
  }
`;

const Column = styled(ColumnBase)`
  @media ${props => props.theme.query.desktop} {
    margin-bottom: 65px;
  }
  @media ${props => props.theme.query.tablet} {
    margin-bottom: 50px;
  }
  @media ${props => props.theme.query.mobile} {
    margin: 0px 0px 30px 0px;
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;
  }
`;

const Country = styled.div`
  margin-bottom: 10px;

  svg {
    width: 70px;
    height: 70px;
  }
`;

const PhoneNumber = styled.a`
  ${props => props.theme.typo.displayM};
  ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.color.g500};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const CountryName = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.black};
  ${props => props.theme.typo.displayM};
  ${props => props.theme.fontWeight.demi};
`;

const Header = styled.h2`
  text-align: center;
  color: ${props => props.theme.color.black};

  @media ${props => props.theme.query.desktop} {
    ${props => props.theme.typo.displayXL};
    ${props => props.theme.fontWeight.demi};
    margin-bottom: 100px;
  }
  @media ${props => props.theme.query.tablet} {
    ${props => props.theme.typo.displayXL};
    ${props => props.theme.fontWeight.demi};
    margin-bottom: 50px;
  }
  @media ${props => props.theme.query.mobile} {
    ${props => props.theme.typo.displayM};
    ${props => props.theme.fontWeight.demi};
    margin-bottom: 56px;
  }
`;

const GlobalHeader = styled.h2`
  ${props => props.theme.typo.bodyM};
  ${props => props.theme.fontWeight.demi};
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: ${props => props.theme.color.p300};
  @media ${props => props.theme.query.mobile} {
    margin-bottom: 12px;
  }
`;

export {
  Wrapper,
  Row,
  Column,
  Country,
  PhoneNumber,
  CountryName,
  Header,
  GlobalHeader,
  FlagsWrapper,
};
