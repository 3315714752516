import createIcon from '@molecules/icons/createIcon';
import React from 'react';

const UsaFlagIcon = createIcon({
  viewBox: '0 0 70 70',
  path: (
    <>
      <path
        d="M68.7929 57.9309H1.20695C0.540448 57.9309 0 57.3905 0 56.724V13.2758C0 12.6093 0.540448 12.0688 1.20695 12.0688H68.7931C69.4596 12.0688 70 12.6093 70 13.2758V56.724C69.9999 57.3906 69.4594 57.9309 68.7929 57.9309Z"
        fill="#F5F5F5"
      />
      <path
        d="M69.9999 15.5969H0V13.2758C0 12.6093 0.540448 12.0688 1.20695 12.0688H68.7931C69.4596 12.0688 70 12.6093 70 13.2758L69.9999 15.5969Z"
        fill="#FF4B55"
      />
      <path d="M69.9999 26.1802H0V29.7081H69.9999V26.1802Z" fill="#FF4B55" />
      <path d="M69.9999 19.1243H0V22.6522H69.9999V19.1243Z" fill="#FF4B55" />
      <path
        d="M69.9999 36.7639H1.20695C0.540448 36.7639 0 36.2234 0 35.5569V33.2358H69.9999V36.7639Z"
        fill="#FF4B55"
      />
      <path d="M69.9999 47.3484H0V50.8763H69.9999V47.3484Z" fill="#FF4B55" />
      <path
        d="M68.7929 57.931H1.20695C0.540448 57.931 0 57.3905 0 56.724V54.4031H69.9999V56.7242C69.9999 57.3907 69.4594 57.931 68.7929 57.931Z"
        fill="#FF4B55"
      />
      <path d="M69.9999 40.2922H0V43.8201H69.9999V40.2922Z" fill="#FF4B55" />
      <path
        d="M31.3792 12.0691H1.20695C0.540448 12.0691 0 12.6094 0 13.2759V35.5569C0 36.2234 0.540448 36.7639 1.20695 36.7639H31.3793C32.0459 36.7639 32.5863 36.2234 32.5863 35.5569V13.2759C32.5862 12.6094 32.0457 12.0691 31.3792 12.0691Z"
        fill="#41479B"
      />
      <path
        d="M3.38876 14.8393L3.6559 15.6405L4.50041 15.6469C4.60979 15.6478 4.65504 15.7873 4.56713 15.8522L3.88778 16.354L4.14262 17.1591C4.17571 17.2633 4.0569 17.3497 3.9679 17.286L3.28089 16.795L2.59388 17.2861C2.50487 17.3497 2.38634 17.2634 2.41915 17.1593L2.67399 16.3541L1.99464 15.8524C1.90673 15.7873 1.95198 15.648 2.06136 15.647L2.90587 15.6406L3.17302 14.8394C3.20747 14.7355 3.35403 14.7355 3.38876 14.8393Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.38876 19.0301L3.6559 19.8313L4.50041 19.8377C4.60979 19.8387 4.65504 19.9781 4.56713 20.043L3.88778 20.5448L4.14262 21.3499C4.17571 21.4541 4.0569 21.5405 3.9679 21.4768L3.28089 20.9859L2.59388 21.4769C2.50487 21.5405 2.38634 21.4542 2.41915 21.3501L2.67399 20.5449L1.99464 20.0432C1.90673 19.9781 1.95198 19.8388 2.06136 19.8378L2.90587 19.8314L3.17302 19.0302C3.20747 18.9265 3.35403 18.9265 3.38876 19.0301Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.38876 23.221L3.6559 24.0222L4.50041 24.0286C4.60979 24.0296 4.65504 24.169 4.56713 24.234L3.88778 24.7357L4.14262 25.5409C4.17571 25.645 4.0569 25.7314 3.9679 25.6677L3.28089 25.1768L2.59388 25.6679C2.50487 25.7314 2.38634 25.6452 2.41915 25.541L2.67399 24.7359L1.99464 24.2341C1.90673 24.169 1.95198 24.0297 2.06136 24.0288L2.90587 24.0223L3.17302 23.2212C3.20747 23.1174 3.35403 23.1174 3.38876 23.221Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.38876 27.412L3.6559 28.2132L4.50041 28.2196C4.60979 28.2206 4.65504 28.36 4.56713 28.425L3.88778 28.9267L4.14262 29.7319C4.17571 29.8361 4.0569 29.9225 3.9679 29.8587L3.28089 29.3678L2.59388 29.8589C2.50487 29.9225 2.38634 29.8362 2.41915 29.732L2.67399 28.9269L1.99464 28.4251C1.90673 28.36 1.95198 28.2207 2.06136 28.2198L2.90587 28.2133L3.17302 27.4122C3.20747 27.3083 3.35403 27.3083 3.38876 27.412Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.38876 31.6031L3.6559 32.4042L4.50041 32.4107C4.60979 32.4116 4.65504 32.5511 4.56713 32.616L3.88778 33.1178L4.14262 33.9229C4.17571 34.0271 4.0569 34.1135 3.9679 34.0498L3.28089 33.5588L2.59388 34.0499C2.50487 34.1135 2.38634 34.0272 2.41915 33.923L2.67399 33.1179L1.99464 32.6161C1.90673 32.5511 1.95198 32.4118 2.06136 32.4108L2.90587 32.4044L3.17302 31.6032C3.20747 31.4992 3.35403 31.4992 3.38876 31.6031Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.64169 16.8939L6.90884 17.6951L7.75335 17.7015C7.86272 17.7024 7.90797 17.8419 7.82006 17.9068L7.14071 18.4086L7.39555 19.2137C7.42864 19.3179 7.30983 19.4043 7.22083 19.3406L6.53382 18.8495L5.84681 19.3406C5.7578 19.4042 5.63927 19.3179 5.67208 19.2137L5.92692 18.4086L5.24757 17.9068C5.15966 17.8418 5.20491 17.7024 5.31429 17.7015L6.1588 17.6951L6.42595 16.8939C6.46026 16.7903 6.6071 16.7903 6.64169 16.8939Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.64169 21.0849L6.90884 21.8861L7.75335 21.8925C7.86272 21.8935 7.90797 22.0329 7.82006 22.0979L7.14071 22.5996L7.39555 23.4047C7.42864 23.5089 7.30983 23.5953 7.22083 23.5316L6.53382 23.0405L5.84681 23.5316C5.7578 23.5952 5.63927 23.5091 5.67208 23.4047L5.92692 22.5996L5.24757 22.0979C5.15966 22.0328 5.20491 21.8935 5.31429 21.8925L6.1588 21.8861L6.42595 21.0849C6.46026 20.9811 6.6071 20.9811 6.64169 21.0849Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.64169 25.2761L6.90884 26.0772L7.75335 26.0837C7.86272 26.0846 7.90797 26.2241 7.82006 26.289L7.14071 26.7908L7.39555 27.5959C7.42864 27.7001 7.30983 27.7865 7.22083 27.7228L6.53382 27.2317L5.84681 27.7228C5.7578 27.7863 5.63927 27.7001 5.67208 27.5959L5.92692 26.7908L5.24757 26.289C5.15966 26.2239 5.20491 26.0846 5.31429 26.0837L6.1588 26.0772L6.42595 25.2761C6.46026 25.1723 6.6071 25.1723 6.64169 25.2761Z"
        fill="#F5F5F5"
      />
      <path
        d="M6.64169 29.4669L6.90884 30.2681L7.75335 30.2745C7.86272 30.2754 7.90797 30.4149 7.82006 30.4798L7.14071 30.9816L7.39555 31.7867C7.42864 31.8909 7.30983 31.9773 7.22083 31.9136L6.53382 31.4225L5.84681 31.9136C5.7578 31.9772 5.63927 31.8909 5.67208 31.7867L5.92692 30.9816L5.24757 30.4798C5.15966 30.4148 5.20491 30.2754 5.31429 30.2745L6.1588 30.2681L6.42595 29.4669C6.46026 29.3633 6.6071 29.3633 6.64169 29.4669Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.89462 14.8391L10.1618 15.6402L11.0063 15.6467C11.1157 15.6476 11.1609 15.7871 11.073 15.852L10.3936 16.3538L10.6485 17.1589C10.6816 17.2631 10.5628 17.3495 10.4738 17.2858L9.78675 16.7947L9.09974 17.2858C9.01073 17.3493 8.8922 17.2631 8.92501 17.1589L9.17985 16.3538L8.5005 15.852C8.41259 15.7869 8.45784 15.6476 8.56722 15.6467L9.41173 15.6402L9.67887 14.8391C9.71333 14.7353 9.86003 14.7353 9.89462 14.8391Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.89462 19.0299L10.1618 19.831L11.0063 19.8375C11.1157 19.8384 11.1609 19.9779 11.073 20.0428L10.3936 20.5446L10.6485 21.3497C10.6816 21.4539 10.5628 21.5403 10.4738 21.4766L9.78675 20.9855L9.09974 21.4766C9.01073 21.5402 8.8922 21.4539 8.92501 21.3497L9.17985 20.5446L8.5005 20.0428C8.41259 19.9777 8.45784 19.8384 8.56722 19.8375L9.41173 19.831L9.67887 19.0299C9.71333 18.9262 9.86003 18.9262 9.89462 19.0299Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.89462 23.221L10.1618 24.0222L11.0063 24.0286C11.1157 24.0296 11.1609 24.169 11.073 24.234L10.3936 24.7357L10.6485 25.5409C10.6816 25.6451 10.5628 25.7315 10.4738 25.6678L9.78675 25.1767L9.09974 25.6678C9.01073 25.7313 8.8922 25.6451 8.92501 25.5409L9.17985 24.7357L8.5005 24.234C8.41259 24.1689 8.45784 24.0296 8.56722 24.0286L9.41173 24.0222L9.67887 23.221C9.71333 23.1174 9.86003 23.1174 9.89462 23.221Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.89462 27.4121L10.1618 28.2132L11.0063 28.2196C11.1157 28.2206 11.1609 28.3601 11.073 28.425L10.3936 28.9268L10.6485 29.7319C10.6816 29.8361 10.5628 29.9225 10.4738 29.8588L9.78675 29.3677L9.09974 29.8588C9.01073 29.9223 8.8922 29.8361 8.92501 29.7319L9.17985 28.9268L8.5005 28.425C8.41259 28.3599 8.45784 28.2206 8.56722 28.2196L9.41173 28.2132L9.67887 27.4121C9.71333 27.3083 9.86003 27.3083 9.89462 27.4121Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.89462 31.6031L10.1618 32.4042L11.0063 32.4107C11.1157 32.4116 11.1609 32.5511 11.073 32.616L10.3936 33.1178L10.6485 33.9229C10.6816 34.0271 10.5628 34.1135 10.4738 34.0498L9.78675 33.5587L9.09974 34.0498C9.01073 34.1134 8.8922 34.0271 8.92501 33.9229L9.17985 33.1178L8.5005 32.616C8.41259 32.5509 8.45784 32.4116 8.56722 32.4107L9.41173 32.4042L9.67887 31.6031C9.71333 31.4992 9.86003 31.4992 9.89462 31.6031Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.1475 16.8939L13.4147 17.6951L14.2592 17.7015C14.3686 17.7024 14.4138 17.8419 14.3259 17.9068L13.6466 18.4086L13.9014 19.2137C13.9345 19.3179 13.8157 19.4043 13.7267 19.3406L13.0397 18.8495L12.3527 19.3406C12.2637 19.4042 12.1451 19.3179 12.1779 19.2137L12.4328 18.4086L11.7534 17.9068C11.6655 17.8418 11.7108 17.7024 11.8201 17.7015L12.6647 17.6951L12.9318 16.8939C12.9661 16.7903 13.113 16.7903 13.1475 16.8939Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.1475 21.0849L13.4147 21.8861L14.2592 21.8925C14.3686 21.8935 14.4138 22.0329 14.3259 22.0979L13.6466 22.5996L13.9014 23.4047C13.9345 23.5089 13.8157 23.5953 13.7267 23.5316L13.0397 23.0405L12.3527 23.5316C12.2637 23.5952 12.1451 23.5091 12.1779 23.4047L12.4328 22.5996L11.7534 22.0979C11.6655 22.0328 11.7108 21.8935 11.8201 21.8925L12.6647 21.8861L12.9318 21.0849C12.9661 20.9811 13.113 20.9811 13.1475 21.0849Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.1475 25.2761L13.4147 26.0772L14.2592 26.0837C14.3686 26.0846 14.4138 26.2241 14.3259 26.289L13.6466 26.7908L13.9014 27.5959C13.9345 27.7001 13.8157 27.7865 13.7267 27.7228L13.0397 27.2317L12.3527 27.7228C12.2637 27.7863 12.1451 27.7001 12.1779 27.5959L12.4328 26.7908L11.7534 26.289C11.6655 26.2239 11.7108 26.0846 11.8201 26.0837L12.6647 26.0772L12.9318 25.2761C12.9661 25.1723 13.113 25.1723 13.1475 25.2761Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.1475 29.4669L13.4147 30.2681L14.2592 30.2745C14.3686 30.2754 14.4138 30.4149 14.3259 30.4798L13.6466 30.9816L13.9014 31.7867C13.9345 31.8909 13.8157 31.9773 13.7267 31.9136L13.0397 31.4225L12.3527 31.9136C12.2637 31.9772 12.1451 31.8909 12.1779 31.7867L12.4328 30.9816L11.7534 30.4798C11.6655 30.4148 11.7108 30.2754 11.8201 30.2745L12.6647 30.2681L12.9318 29.4669C12.9661 29.3633 13.113 29.3633 13.1475 29.4669Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.4006 14.8391L16.6678 15.6402L17.5123 15.6467C17.6217 15.6476 17.6669 15.7871 17.579 15.852L16.8996 16.3538L17.1545 17.1589C17.1876 17.2631 17.0688 17.3495 16.9798 17.2858L16.2926 16.7947L15.6056 17.2858C15.5166 17.3493 15.3981 17.2631 15.4309 17.1589L15.6857 16.3538L15.0064 15.852C14.9184 15.7869 14.9637 15.6476 15.0731 15.6467L15.9176 15.6402L16.1847 14.8391C16.2193 14.7353 16.366 14.7353 16.4006 14.8391Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.4006 19.0299L16.6678 19.831L17.5123 19.8375C17.6217 19.8384 17.6669 19.9779 17.579 20.0428L16.8996 20.5446L17.1545 21.3497C17.1876 21.4539 17.0688 21.5403 16.9798 21.4766L16.2926 20.9855L15.6056 21.4766C15.5166 21.5402 15.3981 21.4539 15.4309 21.3497L15.6857 20.5446L15.0064 20.0428C14.9184 19.9777 14.9637 19.8384 15.0731 19.8375L15.9176 19.831L16.1847 19.0299C16.2193 18.9262 16.366 18.9262 16.4006 19.0299Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.4006 23.221L16.6678 24.0222L17.5123 24.0286C17.6217 24.0296 17.6669 24.169 17.579 24.234L16.8996 24.7357L17.1545 25.5409C17.1876 25.6451 17.0688 25.7315 16.9798 25.6678L16.2926 25.1767L15.6056 25.6678C15.5166 25.7313 15.3981 25.6451 15.4309 25.5409L15.6857 24.7357L15.0064 24.234C14.9184 24.1689 14.9637 24.0296 15.0731 24.0286L15.9176 24.0222L16.1847 23.221C16.2193 23.1174 16.366 23.1174 16.4006 23.221Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.4006 27.4121L16.6678 28.2132L17.5123 28.2196C17.6217 28.2206 17.6669 28.3601 17.579 28.425L16.8996 28.9268L17.1545 29.7319C17.1876 29.8361 17.0688 29.9225 16.9798 29.8588L16.2926 29.3677L15.6056 29.8588C15.5166 29.9223 15.3981 29.8361 15.4309 29.7319L15.6857 28.9268L15.0064 28.425C14.9184 28.3599 14.9637 28.2206 15.0731 28.2196L15.9176 28.2132L16.1847 27.4121C16.2193 27.3083 16.366 27.3083 16.4006 27.4121Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.4006 31.6031L16.6678 32.4042L17.5123 32.4107C17.6217 32.4116 17.6669 32.5511 17.579 32.616L16.8996 33.1178L17.1545 33.9229C17.1876 34.0271 17.0688 34.1135 16.9798 34.0498L16.2926 33.5587L15.6056 34.0498C15.5166 34.1134 15.3981 34.0271 15.4309 33.9229L15.6857 33.1178L15.0064 32.616C14.9184 32.5509 14.9637 32.4116 15.0731 32.4107L15.9176 32.4042L16.1847 31.6031C16.2193 31.4992 16.366 31.4992 16.4006 31.6031Z"
        fill="#F5F5F5"
      />
      <path
        d="M19.6534 16.8939L19.9206 17.6951L20.7651 17.7015C20.8744 17.7024 20.9197 17.8419 20.8318 17.9068L20.1524 18.4086L20.4073 19.2137C20.4404 19.3179 20.3215 19.4043 20.2325 19.3406L19.5455 18.8495L18.8585 19.3406C18.7695 19.4042 18.651 19.3179 18.6838 19.2137L18.9386 18.4086L18.2593 17.9068C18.1714 17.8418 18.2166 17.7024 18.326 17.7015L19.1705 17.6951L19.4377 16.8939C19.4723 16.7903 19.6188 16.7903 19.6534 16.8939Z"
        fill="#F5F5F5"
      />
      <path
        d="M19.6534 21.0849L19.9206 21.8861L20.7651 21.8925C20.8744 21.8935 20.9197 22.0329 20.8318 22.0979L20.1524 22.5996L20.4073 23.4047C20.4404 23.5089 20.3215 23.5953 20.2325 23.5316L19.5455 23.0405L18.8585 23.5316C18.7695 23.5952 18.651 23.5091 18.6838 23.4047L18.9386 22.5996L18.2593 22.0979C18.1714 22.0328 18.2166 21.8935 18.326 21.8925L19.1705 21.8861L19.4377 21.0849C19.4723 20.9811 19.6188 20.9811 19.6534 21.0849Z"
        fill="#F5F5F5"
      />
      <path
        d="M19.6534 25.2761L19.9206 26.0772L20.7651 26.0837C20.8744 26.0846 20.9197 26.2241 20.8318 26.289L20.1524 26.7908L20.4073 27.5959C20.4404 27.7001 20.3215 27.7865 20.2325 27.7228L19.5455 27.2317L18.8585 27.7228C18.7695 27.7863 18.651 27.7001 18.6838 27.5959L18.9386 26.7908L18.2593 26.289C18.1714 26.2239 18.2166 26.0846 18.326 26.0837L19.1705 26.0772L19.4377 25.2761C19.4723 25.1723 19.6188 25.1723 19.6534 25.2761Z"
        fill="#F5F5F5"
      />
      <path
        d="M19.6534 29.4669L19.9206 30.2681L20.7651 30.2745C20.8744 30.2754 20.9197 30.4149 20.8318 30.4798L20.1524 30.9816L20.4073 31.7867C20.4404 31.8909 20.3215 31.9773 20.2325 31.9136L19.5455 31.4225L18.8585 31.9136C18.7695 31.9772 18.651 31.8909 18.6838 31.7867L18.9386 30.9816L18.2593 30.4798C18.1714 30.4148 18.2166 30.2754 18.326 30.2745L19.1705 30.2681L19.4377 29.4669C19.4723 29.3633 19.6188 29.3633 19.6534 29.4669Z"
        fill="#F5F5F5"
      />
      <path
        d="M22.9063 14.8391L23.1735 15.6402L24.018 15.6467C24.1274 15.6476 24.1726 15.7871 24.0847 15.852L23.4054 16.3538L23.6602 17.1589C23.6933 17.2631 23.5745 17.3495 23.4855 17.2858L22.7985 16.7947L22.1115 17.2858C22.0225 17.3493 21.9039 17.2631 21.9367 17.1589L22.1916 16.3538L21.5122 15.852C21.4243 15.7869 21.4696 15.6476 21.5789 15.6467L22.4234 15.6402L22.6906 14.8391C22.7249 14.7353 22.8717 14.7353 22.9063 14.8391Z"
        fill="#F5F5F5"
      />
      <path
        d="M22.9063 19.0299L23.1735 19.831L24.018 19.8375C24.1274 19.8384 24.1726 19.9779 24.0847 20.0428L23.4054 20.5446L23.6602 21.3497C23.6933 21.4539 23.5745 21.5403 23.4855 21.4766L22.7985 20.9855L22.1115 21.4766C22.0225 21.5402 21.9039 21.4539 21.9367 21.3497L22.1916 20.5446L21.5122 20.0428C21.4243 19.9777 21.4696 19.8384 21.5789 19.8375L22.4234 19.831L22.6906 19.0299C22.7249 18.9262 22.8717 18.9262 22.9063 19.0299Z"
        fill="#F5F5F5"
      />
      <path
        d="M22.9063 23.221L23.1735 24.0222L24.018 24.0286C24.1274 24.0296 24.1726 24.169 24.0847 24.234L23.4054 24.7357L23.6602 25.5409C23.6933 25.6451 23.5745 25.7315 23.4855 25.6678L22.7985 25.1767L22.1115 25.6678C22.0225 25.7313 21.9039 25.6451 21.9367 25.5409L22.1916 24.7357L21.5122 24.234C21.4243 24.1689 21.4696 24.0296 21.5789 24.0286L22.4234 24.0222L22.6906 23.221C22.7249 23.1174 22.8717 23.1174 22.9063 23.221Z"
        fill="#F5F5F5"
      />
      <path
        d="M22.9063 27.4121L23.1735 28.2132L24.018 28.2196C24.1274 28.2206 24.1726 28.3601 24.0847 28.425L23.4054 28.9268L23.6602 29.7319C23.6933 29.8361 23.5745 29.9225 23.4855 29.8588L22.7985 29.3677L22.1115 29.8588C22.0225 29.9223 21.9039 29.8361 21.9367 29.7319L22.1916 28.9268L21.5122 28.425C21.4243 28.3599 21.4696 28.2206 21.5789 28.2196L22.4234 28.2132L22.6906 27.4121C22.7249 27.3083 22.8717 27.3083 22.9063 27.4121Z"
        fill="#F5F5F5"
      />
      <path
        d="M22.9063 31.6031L23.1735 32.4042L24.018 32.4107C24.1274 32.4116 24.1726 32.5511 24.0847 32.616L23.4054 33.1178L23.6602 33.9229C23.6933 34.0271 23.5745 34.1135 23.4855 34.0498L22.7985 33.5587L22.1115 34.0498C22.0225 34.1134 21.9039 34.0271 21.9367 33.9229L22.1916 33.1178L21.5122 32.616C21.4243 32.5509 21.4696 32.4116 21.5789 32.4107L22.4234 32.4042L22.6906 31.6031C22.7249 31.4992 22.8717 31.4992 22.9063 31.6031Z"
        fill="#F5F5F5"
      />
      <path
        d="M26.1593 16.8939L26.4264 17.6951L27.2709 17.7015C27.3803 17.7024 27.4256 17.8419 27.3376 17.9068L26.6583 18.4086L26.9131 19.2137C26.9462 19.3179 26.8274 19.4043 26.7384 19.3406L26.0514 18.8495L25.3644 19.3406C25.2754 19.4042 25.1568 19.3179 25.1897 19.2137L25.4445 18.4086L24.7651 17.9068C24.6772 17.8418 24.7225 17.7024 24.8319 17.7015L25.6764 17.6951L25.9435 16.8939C25.978 16.7903 26.1248 16.7903 26.1593 16.8939Z"
        fill="#F5F5F5"
      />
      <path
        d="M26.1593 21.0849L26.4264 21.8861L27.2709 21.8925C27.3803 21.8935 27.4256 22.0329 27.3376 22.0979L26.6583 22.5996L26.9131 23.4047C26.9462 23.5089 26.8274 23.5953 26.7384 23.5316L26.0514 23.0405L25.3644 23.5316C25.2754 23.5952 25.1568 23.5091 25.1897 23.4047L25.4445 22.5996L24.7651 22.0979C24.6772 22.0328 24.7225 21.8935 24.8319 21.8925L25.6764 21.8861L25.9435 21.0849C25.978 20.9811 26.1248 20.9811 26.1593 21.0849Z"
        fill="#F5F5F5"
      />
      <path
        d="M26.1593 25.2761L26.4264 26.0772L27.2709 26.0837C27.3803 26.0846 27.4256 26.2241 27.3376 26.289L26.6583 26.7908L26.9131 27.5959C26.9462 27.7001 26.8274 27.7865 26.7384 27.7228L26.0514 27.2317L25.3644 27.7228C25.2754 27.7863 25.1568 27.7001 25.1897 27.5959L25.4445 26.7908L24.7651 26.289C24.6772 26.2239 24.7225 26.0846 24.8319 26.0837L25.6764 26.0772L25.9435 25.2761C25.978 25.1723 26.1248 25.1723 26.1593 25.2761Z"
        fill="#F5F5F5"
      />
      <path
        d="M26.1593 29.4669L26.4264 30.2681L27.2709 30.2745C27.3803 30.2754 27.4256 30.4149 27.3376 30.4798L26.6583 30.9816L26.9131 31.7867C26.9462 31.8909 26.8274 31.9773 26.7384 31.9136L26.0514 31.4225L25.3644 31.9136C25.2754 31.9772 25.1568 31.8909 25.1897 31.7867L25.4445 30.9816L24.7651 30.4798C24.6772 30.4148 24.7225 30.2754 24.8319 30.2745L25.6764 30.2681L25.9435 29.4669C25.978 29.3633 26.1248 29.3633 26.1593 29.4669Z"
        fill="#F5F5F5"
      />
      <path
        d="M29.4132 14.8391L29.6803 15.6402L30.5248 15.6467C30.6342 15.6476 30.6795 15.7871 30.5916 15.852L29.9122 16.3538L30.167 17.1589C30.2001 17.2631 30.0813 17.3495 29.9923 17.2858L29.3053 16.7947L28.6183 17.2858C28.5293 17.3493 28.4108 17.2631 28.4436 17.1589L28.6984 16.3538L28.0191 15.852C27.9311 15.7869 27.9764 15.6476 28.0858 15.6467L28.9303 15.6402L29.1974 14.8391C29.2319 14.7353 29.3784 14.7353 29.4132 14.8391Z"
        fill="#F5F5F5"
      />
      <path
        d="M29.4132 19.0299L29.6803 19.831L30.5248 19.8375C30.6342 19.8384 30.6795 19.9779 30.5916 20.0428L29.9122 20.5446L30.167 21.3497C30.2001 21.4539 30.0813 21.5403 29.9923 21.4766L29.3053 20.9855L28.6183 21.4766C28.5293 21.5402 28.4108 21.4539 28.4436 21.3497L28.6984 20.5446L28.0191 20.0428C27.9311 19.9777 27.9764 19.8384 28.0858 19.8375L28.9303 19.831L29.1974 19.0299C29.2319 18.9262 29.3784 18.9262 29.4132 19.0299Z"
        fill="#F5F5F5"
      />
      <path
        d="M29.4132 23.221L29.6803 24.0222L30.5248 24.0286C30.6342 24.0296 30.6795 24.169 30.5916 24.234L29.9122 24.7357L30.167 25.5409C30.2001 25.6451 30.0813 25.7315 29.9923 25.6678L29.3053 25.1767L28.6183 25.6678C28.5293 25.7313 28.4108 25.6451 28.4436 25.5409L28.6984 24.7357L28.0191 24.234C27.9311 24.1689 27.9764 24.0296 28.0858 24.0286L28.9303 24.0222L29.1974 23.221C29.2319 23.1174 29.3784 23.1174 29.4132 23.221Z"
        fill="#F5F5F5"
      />
      <path
        d="M29.4132 27.4121L29.6803 28.2132L30.5248 28.2196C30.6342 28.2206 30.6795 28.3601 30.5916 28.425L29.9122 28.9268L30.167 29.7319C30.2001 29.8361 30.0813 29.9225 29.9923 29.8588L29.3053 29.3677L28.6183 29.8588C28.5293 29.9223 28.4108 29.8361 28.4436 29.7319L28.6984 28.9268L28.0191 28.425C27.9311 28.3599 27.9764 28.2206 28.0858 28.2196L28.9303 28.2132L29.1974 27.4121C29.2319 27.3083 29.3784 27.3083 29.4132 27.4121Z"
        fill="#F5F5F5"
      />
      <path
        d="M29.4132 31.6031L29.6803 32.4042L30.5248 32.4107C30.6342 32.4116 30.6795 32.5511 30.5916 32.616L29.9122 33.1178L30.167 33.9229C30.2001 34.0271 30.0813 34.1135 29.9923 34.0498L29.3053 33.5587L28.6183 34.0498C28.5293 34.1134 28.4108 34.0271 28.4436 33.9229L28.6984 33.1178L28.0191 32.616C27.9311 32.5509 27.9764 32.4116 28.0858 32.4107L28.9303 32.4042L29.1974 31.6031C29.2319 31.4992 29.3784 31.4992 29.4132 31.6031Z"
        fill="#F5F5F5"
      />
    </>
  ),
});

export { UsaFlagIcon };
