import styled from 'styled-components';

import { Column as ColumnBase, Row as RowBase } from '@atoms/Grid';

const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.color.black};
  @media ${props => props.theme.query.tablet} {
    margin-top: 30px;
  }
  @media ${props => props.theme.query.mobile} {
    margin-top: 30px;
  }
`;

const InnerWrapper = styled.div`
  @media ${props => props.theme.query.tablet} {
    padding: 20px 15px;
  }
  @media ${props => props.theme.query.mobile} {
    padding: 10px 5px;
  }
  @media ${props => props.theme.query.desktop} {
    width: 100%;
    padding: 20px 15px;
  }
`;

const Image = styled.img`
  vertical-align: bottom;
  @media ${props => props.theme.query.mobile} {
    height: 20px;
  }
`;

const Row = styled(RowBase)`
  margin: 0;
  justify-content: center;
  align: center;
  max-width: 1440px;
  margin-right: auto;

  @media ${props => props.theme.query.desktop} {
    margin-left: auto;
  }
`;

const Column = styled(ColumnBase)`
  justify-content: center;
  align: center;

  @media ${props => props.theme.query.mobile} {
    text-align: left;
    margin-top: 5px;
  }
`;

const Text = styled.span`
  ${props => props.theme.fontWeight.medium};
  ${props => props.theme.typo.bodyM};
  color: ${props => props.theme.color.white};
  margin-top: 20px;
  margin-left: 5px;

  @media ${props => props.theme.query.desktop} {
    display: inline;
  }
`;

export { Container, InnerWrapper, Image, Row, Column, Text };
