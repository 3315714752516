import createIcon from '@molecules/icons/createIcon';
import React from 'react';

const DominicanRepublicFlagIcon = createIcon({
  viewBox: '0 0 70 70',
  path: (
    <>
      <path
        d="M68.7929 57.9309H1.20695C0.540448 57.9309 0 57.3905 0 56.724V13.2758C0 12.6093 0.540448 12.0688 1.20695 12.0688H68.7931C69.4596 12.0688 70 12.6093 70 13.2758V56.724C69.9999 57.3906 69.4594 57.9309 68.7929 57.9309Z"
        fill="#F5F5F5"
      />
      <path
        d="M28.9655 30.1724H1.20695C0.540448 30.1724 0 29.632 0 28.9655V13.2758C0 12.6093 0.540448 12.0688 1.20695 12.0688H28.9655C29.632 12.0688 30.1724 12.6093 30.1724 13.2758V28.9655C30.1724 29.632 29.632 30.1724 28.9655 30.1724Z"
        fill="#41479B"
      />
      <path
        d="M68.7926 57.9308H41.0341C40.3676 57.9308 39.8271 57.3904 39.8271 56.7239V41.0343C39.8271 40.3678 40.3676 39.8274 41.0341 39.8274H68.7926C69.4591 39.8274 69.9996 40.3678 69.9996 41.0343V56.724C69.9996 57.3905 69.4591 57.9308 68.7926 57.9308Z"
        fill="#41479B"
      />
      <path
        d="M68.7926 30.1724H41.0341C40.3676 30.1724 39.8271 29.632 39.8271 28.9655V13.2758C39.8271 12.6093 40.3676 12.0688 41.0341 12.0688H68.7926C69.4591 12.0688 69.9996 12.6093 69.9996 13.2758V28.9655C69.9996 29.632 69.4591 30.1724 68.7926 30.1724Z"
        fill="#FF4B55"
      />
      <path
        d="M28.9655 57.9308H1.20695C0.540448 57.9308 0 57.3904 0 56.7239V41.0343C0 40.3678 0.540448 39.8274 1.20695 39.8274H28.9655C29.632 39.8274 30.1724 40.3678 30.1724 41.0343V56.724C30.1724 57.3905 29.632 57.9308 28.9655 57.9308Z"
        fill="#FF4B55"
      />
      <path
        d="M34.3076 38.4416C34.3076 38.4416 35.0287 38.7056 34.9346 39.6108L35.2261 39.6053C35.2261 39.6053 35.0422 38.5593 34.8902 38.4339C34.7381 38.3086 34.3076 38.4416 34.3076 38.4416Z"
        fill="#64B496"
      />
      <path
        d="M35.0301 38.3017C34.7243 38.3017 34.1897 38.3013 33.6275 38.1496C33.7529 38.0191 33.9261 37.7564 33.9261 37.2982C33.9261 36.5807 33.4317 37.0385 33.4317 37.0385C33.4317 37.0385 33.1066 37.312 33.3032 38.0433C33.1115 37.9702 32.9214 37.8759 32.7401 37.755C32.8529 37.6134 33.0779 37.2833 33.2086 36.76C33.2086 36.76 32.3847 36.5971 32.1594 37.2176C32.0213 37.0424 31.9032 36.8454 31.8046 36.6273C32.0164 36.6299 32.3119 36.4902 32.3119 35.6839C32.3119 35.6839 31.7739 35.6848 31.6302 36.1514C31.5736 35.9563 31.5305 35.7473 31.4988 35.5264C31.7136 35.5887 32.2689 35.6334 32.2689 34.4282C32.2689 34.4282 31.6022 34.4295 31.4478 35.0201C31.4393 34.869 31.436 34.7129 31.4376 34.5518C31.7613 34.3826 32.2081 34.0097 32.1618 33.2448C32.1618 33.2448 31.8543 33.3145 31.5724 33.5509C31.6279 33.2609 31.6049 32.8364 31.1602 32.4822C31.1602 32.4822 30.7515 33.2054 31.3484 33.7926C31.2965 33.8656 31.2512 33.9488 31.2139 34.041C31.0798 33.8368 30.8647 33.6469 30.5183 33.5315C30.5183 33.5315 30.3588 34.5001 31.2632 34.9054C31.2847 35.4711 31.3732 35.971 31.5252 36.4056C31.2661 36.1774 31.0565 35.8632 31.0565 35.8632C31.0565 34.787 30.1743 34.7598 30.1743 34.7598C30.0095 35.9882 30.9179 36.0378 30.9179 36.0378L31.4153 36.5806C30.339 36.4012 30.339 37.2981 30.339 37.2981C31.0565 37.4775 31.4153 37.1187 31.4153 37.1187L31.8033 37.3182C31.115 37.3383 30.8771 38.0156 30.8771 38.0156C31.7422 38.1598 32.3746 37.9564 32.5897 37.8711C33.4608 38.4802 34.5346 38.481 35.0304 38.481V38.3017H35.0301Z"
        fill="#64B496"
      />
      <path
        d="M34.9688 38.3017C35.2746 38.3017 35.8092 38.3013 36.3713 38.1496C36.246 38.0191 36.0727 37.7564 36.0727 37.2982C36.0727 36.5807 36.5671 37.0385 36.5671 37.0385C36.5671 37.0385 36.8922 37.312 36.6956 38.0433C36.8873 37.9702 37.0775 37.8759 37.2588 37.755C37.146 37.6134 36.921 37.2833 36.7903 36.76C36.7903 36.76 37.6141 36.5971 37.8394 37.2176C37.9775 37.0424 38.0956 36.8454 38.1942 36.6273C37.9824 36.6299 37.687 36.4902 37.687 35.6839C37.687 35.6839 38.225 35.6848 38.3689 36.1514C38.4255 35.9563 38.4686 35.7473 38.5003 35.5264C38.2855 35.5887 37.7302 35.6334 37.7302 34.4282C37.7302 34.4282 38.397 34.4295 38.5513 35.0201C38.5598 34.869 38.5631 34.7129 38.5616 34.5518C38.2378 34.3826 37.791 34.0097 37.8374 33.2448C37.8374 33.2448 38.1449 33.3145 38.4268 33.5509C38.3713 33.2609 38.3942 32.8364 38.839 32.4822C38.839 32.4822 39.2476 33.2054 38.6507 33.7926C38.7027 33.8656 38.7479 33.9488 38.7852 34.041C38.9194 33.8368 39.1344 33.6469 39.4809 33.5315C39.4809 33.5315 39.6403 34.5001 38.7359 34.9054C38.7144 35.4711 38.626 35.971 38.4739 36.4056C38.733 36.1774 38.9426 35.8632 38.9426 35.8632C38.9426 34.787 39.8249 34.7598 39.8249 34.7598C39.9896 35.9882 39.0812 36.0378 39.0812 36.0378L38.5839 36.5806C39.6601 36.4012 39.6601 37.2981 39.6601 37.2981C38.9426 37.4775 38.5839 37.1187 38.5839 37.1187L38.1959 37.3182C38.8841 37.3383 39.122 38.0156 39.122 38.0156C38.257 38.1598 37.6245 37.9564 37.4094 37.8711C36.5383 38.4802 35.4645 38.481 34.9688 38.481V38.3017Z"
        fill="#64B496"
      />
      <path
        d="M31.9737 30.4822C31.3429 30.8865 30.7869 31.4012 30.3389 32.0089C30.5314 32.1508 30.7239 32.2929 30.9164 32.4348C31.3118 31.8984 31.8024 31.4443 32.3589 31.0873L31.9737 30.4822Z"
        fill="#41479B"
      />
      <path
        d="M35.0021 35.1458H37.5132V36.5807C37.5132 36.9771 37.192 37.2983 36.7957 37.2983H35.7194C35.4247 37.2983 35.1655 37.4424 35.002 37.6619L35.0021 35.1458Z"
        fill="#FF6B71"
      />
      <path
        d="M35.54 33.5315V31.5583H38.2307L37.5132 32.2758V34.249H35.54V33.5315Z"
        fill="#5F64B9"
      />
      <path
        d="M37.5131 34.248H36.6162V35.1449H37.5131V34.248Z"
        fill="#F5F5F5"
      />
      <path
        d="M35.0023 35.1458H32.4912V36.5807C32.4912 36.9771 32.8124 37.2983 33.2087 37.2983H34.285C34.5797 37.2983 34.8389 37.4424 35.0025 37.6619L35.0023 35.1458Z"
        fill="#FF6B71"
      />
      <path
        d="M34.5537 37.3416C34.7327 37.3988 34.8872 37.5102 34.998 37.657H35.0064C35.1174 37.5101 35.2718 37.3989 35.4507 37.3416V35.8633H34.5538L34.5537 37.3416Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.4641 33.5315V31.5583H31.7734L32.4909 32.2758V34.249H34.4641V33.5315Z"
        fill="#5F64B9"
      />
      <path
        d="M33.3881 34.248H32.4912V35.1449H33.3881V34.248Z"
        fill="#F5F5F5"
      />
      <path
        d="M33.3877 32.4551V32.9932L33.9258 33.352V32.9932L33.3877 32.4551Z"
        fill="#41479B"
      />
      <path
        d="M33.9258 32.9934V33.5315L34.6433 33.8903V33.7109L33.9258 32.9934Z"
        fill="#F5F5F5"
      />
      <path
        d="M36.6162 32.4551V32.9932L36.0781 33.352V32.9932L36.6162 32.4551Z"
        fill="#41479B"
      />
      <path
        d="M36.0778 32.9934V33.5315L35.3604 33.8903V33.7109L36.0778 32.9934Z"
        fill="#F5F5F5"
      />
      <path
        d="M36.0841 36.4013H33.92C33.4281 36.4013 33.0293 35.9997 33.0293 35.5044V32.7302L35.0151 34.0694L36.9747 32.7302V35.5044C36.9747 35.9998 36.576 36.4013 36.0841 36.4013Z"
        fill="#FF4B55"
      />
      <path
        d="M33.7468 33.1726L33.0293 32.6345V34.6076L33.7468 34.9664V33.1726Z"
        fill="#41479B"
      />
      <path
        d="M36.2578 33.1726L36.9753 32.6345V34.6076L36.2578 34.9664V33.1726Z"
        fill="#41479B"
      />
      <path
        d="M36.0783 34.9673H33.9258V35.8642H36.0783V34.9673Z"
        fill="#41479B"
      />
      <path
        d="M33.9258 33.3521L35.0019 33.8902L36.0781 33.3521V34.9664H33.9258V33.3521Z"
        fill="#FF4B55"
      />
      <path
        d="M35.8085 33.5217V35.3253C35.8085 35.4736 35.6877 35.5942 35.5395 35.5942H34.4633C34.315 35.5942 34.1944 35.4735 34.1944 35.3253V33.5291L33.6562 33.1687V35.3253C33.6562 35.7703 34.0183 36.1323 34.4633 36.1323H35.5395C35.9846 36.1323 36.3466 35.7703 36.3466 35.3253V33.1565L35.8085 33.5217Z"
        fill="#F5F5F5"
      />
      <path
        d="M35.9966 34.7745C35.6945 34.8017 35.3545 34.8322 35.0016 34.9549C34.6489 34.8322 34.3087 34.8017 34.0066 34.7745C33.501 34.7291 33.201 34.6849 33.0293 34.3799V34.8976C33.293 35.0691 33.627 35.1005 33.9744 35.1318C34.2863 35.1599 34.6087 35.1887 34.9382 35.3135L35.0017 35.3375L35.0653 35.3135C35.3947 35.1887 35.717 35.16 36.029 35.1318C36.3767 35.1007 36.7113 35.0696 36.975 34.8979V34.3787C36.8031 34.6849 36.5029 34.7291 35.9966 34.7745Z"
        fill="#F5F5F5"
      />
      <path
        d="M35.3609 33.083H35.092V32.814H34.9126V33.083H34.6436V33.2624H34.9126V34.0696H35.092V33.2624H35.3609V33.083Z"
        fill="#FFE15A"
      />
      <path
        d="M33.3318 32.2693L33.2334 32.3677L34.8096 33.9438L34.908 33.8454L33.3318 32.2693Z"
        fill="#464655"
      />
      <path
        d="M32.9873 32.0225L33.1843 32.4165L33.3813 32.2195L32.9873 32.0225Z"
        fill="#FFE15A"
      />
      <path
        d="M33.6946 32.0137L33.582 32.0957L34.8946 33.8976L35.0072 33.8155L33.6946 32.0137Z"
        fill="#464655"
      />
      <path
        d="M33.3906 31.7166L33.5242 32.1364L33.7494 31.9724L33.3906 31.7166Z"
        fill="#FFE15A"
      />
      <path
        d="M33.0191 32.5983L32.9395 32.7124L34.7671 33.9885L34.8467 33.8744L33.0191 32.5983Z"
        fill="#464655"
      />
      <path
        d="M32.6357 32.4172L32.8987 32.7707L33.0582 32.5423L32.6357 32.4172Z"
        fill="#FFE15A"
      />
      <path
        d="M35.0949 33.8442L35.1934 33.9426L36.7695 32.3665L36.6711 32.268L35.0949 33.8442Z"
        fill="#464655"
      />
      <path
        d="M37.0161 32.0225L36.8191 32.4165L36.6221 32.2195L37.0161 32.0225Z"
        fill="#FFE15A"
      />
      <path
        d="M34.9977 33.814L35.1104 33.896L36.4229 32.0941L36.3103 32.0121L34.9977 33.814Z"
        fill="#464655"
      />
      <path
        d="M36.6125 31.7166L36.4789 32.1364L36.2539 31.9724L36.6125 31.7166Z"
        fill="#FFE15A"
      />
      <path
        d="M35.1586 33.8764L35.2383 33.9905L37.0659 32.7143L36.9862 32.6002L35.1586 33.8764Z"
        fill="#464655"
      />
      <path
        d="M37.3668 32.4172L37.1039 32.7707L36.9443 32.5423L37.3668 32.4172Z"
        fill="#FFE15A"
      />
      <path
        d="M34.9113 37.2472C34.9113 37.2472 34.2176 36.5023 34.3523 35.8184C34.4605 35.2684 34.7887 34.662 35.0456 34.5145C35.0456 34.5145 35.4089 34.4921 35.3178 34.8076C35.2268 35.1232 35.1157 35.2045 35.1483 35.5156C35.2042 36.054 35.291 36.6919 34.9113 37.2472Z"
        fill="#F5F5F5"
      />
      <path
        d="M35.1209 34.5183C35.0775 34.5126 35.0446 34.5145 35.0446 34.5145C34.7877 34.662 34.4594 35.2683 34.3513 35.8184C34.29 36.1294 34.4006 36.4525 34.539 36.7129C34.4514 35.5852 34.7806 35.1852 35.1209 34.5183Z"
        fill="#41479B"
      />
      <path
        d="M35.1473 35.5158C35.1149 35.2047 35.2257 35.1234 35.3168 34.8079C35.3806 34.5871 35.222 34.5318 35.121 34.5186C35.121 34.5186 34.8894 35.0507 34.8745 35.4076C34.8614 35.7217 34.9418 36.9996 34.9106 37.2476C35.2903 36.6918 35.2035 36.0539 35.1473 35.5158Z"
        fill="#FF4B55"
      />
      <path
        d="M34.6775 34.7845C34.8221 34.6436 35.0252 34.5806 35.2243 34.6147C35.1299 34.3111 35.0356 34.0074 34.9413 33.7036C34.7425 33.6696 34.5392 33.7326 34.3945 33.8734C34.4887 34.1772 34.5832 34.4809 34.6775 34.7845Z"
        fill="#F5F0F0"
      />
      <path
        d="M35.2234 34.6146C35.3679 34.4737 35.5711 34.4106 35.7702 34.4448C35.6758 34.1412 35.5815 33.8375 35.4872 33.5337C35.2884 33.4997 35.0851 33.5627 34.9404 33.7035C35.0346 34.0072 35.129 34.3108 35.2234 34.6146Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.1828 30.6736C33.2091 30.6512 32.2474 30.8906 31.3978 31.3668C31.2809 31.1581 31.1639 30.9495 31.0469 30.741C32.0084 30.2018 33.0971 29.9308 34.1993 29.9562C34.1937 30.1953 34.1881 30.4345 34.1828 30.6736Z"
        fill="#5F64B9"
      />
      <path
        d="M38.0306 30.4822C38.6615 30.8865 39.2175 31.4012 39.6655 32.0089C39.473 32.1508 39.2805 32.2929 39.088 32.4348C38.6926 31.8984 38.202 31.4443 37.6455 31.0873L38.0306 30.4822Z"
        fill="#41479B"
      />
      <path
        d="M35.8212 30.6736C36.7949 30.6512 37.7566 30.8906 38.6062 31.3668C38.7231 31.1581 38.8401 30.9495 38.9571 30.741C37.9956 30.2018 36.9069 29.9308 35.8047 29.9562C35.8103 30.1953 35.8159 30.4345 35.8212 30.6736Z"
        fill="#5F64B9"
      />
      <path
        d="M36.4371 30.4822C35.4978 30.2252 34.5065 30.2252 33.5673 30.4822C33.5043 30.2515 33.441 30.0209 33.3779 29.7901C34.4412 29.4993 35.5631 29.4993 36.6265 29.7901C36.5635 30.0208 36.5003 30.2514 36.4371 30.4822Z"
        fill="#41479B"
      />
      <path
        d="M32.5977 38.5215C31.8235 38.7237 31.0666 38.9964 30.3379 39.3397C30.4399 39.556 30.5419 39.7725 30.6437 39.9887C31.3469 39.6573 32.0778 39.3958 32.8258 39.2037L32.5977 38.5215Z"
        fill="#FF6B71"
      />
      <path
        d="M37.4058 38.5215C38.18 38.7237 38.9369 38.9964 39.6656 39.3397C39.5636 39.556 39.4616 39.7725 39.3598 39.9887C38.6566 39.6573 37.9257 39.3958 37.1777 39.2037L37.4058 38.5215Z"
        fill="#FF6B71"
      />
      <path
        d="M37.8868 39.0763C36.0002 38.6234 34.0336 38.6234 32.147 39.0763C32.0912 38.8436 32.0353 38.6112 31.9795 38.3788C33.976 37.8995 36.0578 37.8995 38.0543 38.3788C37.9987 38.6112 37.9427 38.8439 37.8868 39.0763Z"
        fill="#FF4B55"
      />
      <path
        d="M35.0017 39.2712L34.507 39.395C34.3938 39.4233 34.2842 39.3377 34.2842 39.2211V38.9631C34.2842 38.8465 34.3938 38.7607 34.507 38.7892L35.0017 38.9129V39.2712Z"
        fill="#FF4B55"
      />
      <path
        d="M35.001 38.9126L35.4956 38.7889C35.6088 38.7606 35.7185 38.8462 35.7185 38.9628V39.2208C35.7185 39.3374 35.6088 39.4231 35.4956 39.3947L35.001 39.271V38.9126Z"
        fill="#FF4B55"
      />
      <path
        d="M35.0402 39.2712H34.9632C34.8854 39.2712 34.8223 39.2082 34.8223 39.1303V39.0533C34.8223 38.9755 34.8853 38.9124 34.9632 38.9124H35.0402C35.118 38.9124 35.1812 38.9754 35.1812 39.0533V39.1303C35.181 39.2082 35.118 39.2712 35.0402 39.2712Z"
        fill="#FF6B71"
      />
    </>
  ),
});

export { DominicanRepublicFlagIcon };
