import { Link } from '@atoms/link';
import { ROUTES } from '@infra/routes';
import React from 'react';

const getPageBreadcrumbs = params => {
  const { categoryUrl, categoryTitle, headerTitle, t } = params;

  if (typeof t !== 'function') {
    throw new Error(
      'Translation function was not provided to getPageBreadcrumbs'
    );
  }

  if (t('') !== '') {
    throw new Error(
      'Namespaced translator function was provided to getPageBreadcrumbs'
    );
  }

  const breadcrumbs = [
    {
      title: t('breadcrumbs.home'),
      link: ROUTES.HOME,
    },
  ];

  if (categoryUrl) {
    breadcrumbs.push({
      title: t(categoryTitle),
      link: categoryUrl,
    });
  }

  breadcrumbs.push({
    title: headerTitle,
  });

  return breadcrumbs;
};

const createBreadcrumb = (item, key) => {
  const { link, title } = item;

  return link ? (
    <Link to={link} aria-label={title} key={key} data-testid="breadcrumbs-link">
      {title}
    </Link>
  ) : (
    <p key={key} data-testid="breadcrumbs-p">
      {title}
    </p>
  );
};

export { getPageBreadcrumbs, createBreadcrumb };
