import contactUsHeader from '@images/contact-us-header.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${props => props.theme.color.p400};
  padding-top: 100px;
  padding-bottom: 100px;

  @media ${props => props.theme.query.min.tablet} {
    padding-top: 150px;
    padding-bottom: 150px;
  }
`;

export const Box = styled.div`
  ${props => props.theme.boxShadow.level3};
  border-radius: 10px;
  background-color: ${props => props.theme.color.white};
  max-width: 770px;
  margin: 0 15px;

  @media ${props => props.theme.query.min.tablet} {
    margin: 0 auto;
  }
`;

export const Header = styled.div`
  background-image: url(${contactUsHeader});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 110px 77px;
  border-radius: 10px 10px 0 0;

  background-color: ${props => props.theme.color.g200};
  padding: 30px 15px;

  @media ${props => props.theme.query.min.tablet} {
    padding: 37px 30px;
    background-size: 258px 179px;
  }
`;

export const Title = styled.p`
  color: ${props => props.theme.color.a300};
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 15px;
  text-transform: uppercase;

  @media ${props => props.theme.query.min.tablet} {
    ${props => props.theme.typo.bodyL};
  }
`;

export const SubTitle = styled.h2`
  color: ${props => props.theme.color.p300};
  ${props => props.theme.typo.displayL};
  margin-top: 20px;

  @media ${props => props.theme.query.min.tablet} {
    ${props => props.theme.typo.displayXL};
    margin-top: 30px;
  }
`;

export const Body = styled.div`
  padding: 30px 15px;

  @media ${props => props.theme.query.min.tablet} {
    padding: 50px 90px;
  }
`;
