import { useTranslation } from '@infra/i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Body,
  Box,
  Header,
  SubTitle,
  Title,
  Wrapper,
} from './PardotForm.styled';
import { handleIframeHeight } from './utils';

export const PardotForm = ({ pardotFormTitle, subtitle, pardotURL }) => {
  const [height, setHeight] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('message', handleIframeHeight(setHeight));

    return () => {
      window.removeEventListener('message', handleIframeHeight(setHeight));
    };
  }, []);

  return (
    <Wrapper>
      <Box>
        <Header>
          <Title>{t(pardotFormTitle)}</Title>
          {subtitle && <SubTitle>{t(subtitle)}</SubTitle>}
        </Header>
        <Body>
          <iframe
            src={pardotURL}
            title="contact pardot form"
            height={`${height}px`}
            width="100%"
          />
        </Body>
      </Box>
    </Wrapper>
  );
};

PardotForm.propTypes = {
  pardotFormTitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  pardotURL: PropTypes.string.isRequired,
};
