import { useTranslation } from '@infra/i18n';
import { DominicanRepublicFlagIcon } from '@molecules/icons/DominicanRepublicFlagIcon';
import { IndiaFlagIcon } from '@molecules/icons/IndiaFlagIcon';
import { SpainFlagIcon } from '@molecules/icons/SpainFlagIcon';
import { UAEFlagIcon } from '@molecules/icons/UAEFlagIcon';
import { UsaFlagIcon } from '@molecules/icons/UsaFlagIcon';
import React from 'react';

import {
  Column,
  Country,
  CountryName,
  FlagsWrapper,
  GlobalHeader,
  Header,
  PhoneNumber,
  Row,
  Wrapper,
} from './styled-components';

const PhonesSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper data-testid="default-numbers">
      <GlobalHeader>{t(`contactUs.globalPhoneNumbers`)}</GlobalHeader>
      <Header>{t(`contactUs.phoneSectionHeader`)}</Header>
      <FlagsWrapper>
        <Row>
          <Column md={6} lg={4}>
            <Country>
              <UsaFlagIcon />
            </Country>
            <CountryName>{t(`contactUs.usa`)}</CountryName>
            <PhoneNumber href={`tel:${t(`contactUs.usaPhoneNumber`)}`}>
              {t(`contactUs.usaPhoneNumber`)}
            </PhoneNumber>
          </Column>
          <Column md={6} lg={4}>
            <Country>
              <SpainFlagIcon />
            </Country>
            <CountryName>{t(`contactUs.spain`)}</CountryName>
            <PhoneNumber href={`tel:${t(`contactUs.spainPhoneNumber`)}`}>
              {t(`contactUs.spainPhoneNumber`)}
            </PhoneNumber>
          </Column>
          <Column md={6} lg={4}>
            <Country>
              <DominicanRepublicFlagIcon />
            </Country>
            <CountryName>{t(`contactUs.dominicanRepublic`)}</CountryName>
            <PhoneNumber
              href={`tel:${t(`contactUs.dominicanRepublicPhoneNumber`)}`}
            >
              {t(`contactUs.dominicanRepublicPhoneNumber`)}
            </PhoneNumber>
          </Column>
          <Column md={6} lg={4}>
            <Country>
              <UAEFlagIcon />
            </Country>
            <CountryName>{t(`contactUs.uae`)}</CountryName>
            <PhoneNumber href={`tel:${t(`contactUs.uaePhoneNumber`)}`}>
              {t(`contactUs.uaePhoneNumber`)}
            </PhoneNumber>
          </Column>
          <Column md={6} lg={4}>
            <Country>
              <IndiaFlagIcon />
            </Country>
            <CountryName>{t(`contactUs.india`)}</CountryName>
            <PhoneNumber href={`tel:${t(`contactUs.indiaPhoneNumber`)}`}>
              {t(`contactUs.indiaPhoneNumber`)}
            </PhoneNumber>
          </Column>
        </Row>
      </FlagsWrapper>
    </Wrapper>
  );
};

export { PhonesSection };
