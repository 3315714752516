import createIcon from '@molecules/icons/createIcon';
import React from 'react';

const UAEFlagIcon = createIcon({
  viewBox: '0 0 70 70',
  path: (
    <>
      <path
        d="M70.0003 27.3564H21.7246V12.0691H68.7935C69.46 12.0691 70.0004 12.6095 70.0004 13.276L70.0003 27.3564Z"
        fill="#73AF00"
      />
      <path
        d="M68.7934 57.9311H21.7246V42.6438H70.0003V56.7242C70.0003 57.3908 69.4599 57.9311 68.7934 57.9311Z"
        fill="#464655"
      />
      <path
        d="M69.9999 27.3562H21.7246V42.6427H69.9999V27.3562Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.7242 57.9309H1.20695C0.540448 57.9309 0 57.3905 0 56.724V13.2758C0 12.6093 0.540448 12.0688 1.20695 12.0688H21.7242V57.9309Z"
        fill="#FF4B55"
      />
    </>
  ),
});

export { UAEFlagIcon };
